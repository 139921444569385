import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import _, { includes, isNil, map } from "lodash";
import { CSVLink } from "react-csv";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { red, green } from "@material-ui/core/colors";
import CDialog from "../components/CDialog";
import { isAllowedAction } from "../libs/rbac";
import { getTeamAbbreviation, getTeamName, getZoneLabel, renderRowName } from "../libs/shared/helpers";
import NoteForm from "../components/orders/NoteForm";
import OrderDetails from "../components/orders/OrderDetails";
import OrderNotes from "../components/orders/OrderNotes";
import SearchForm from "../components/orders/SearchForm";
import TransferForm from "../components/orders/TransferForm";
import SeatInfo from "../components/orders/SeatInfo";
import EditNoteForm from "../components/orders/EditNoteForm";
import TransferredDetailsForm from "../components/orders/TransferredDetailsForm";
import EditTransferredDetailsForm from "../components/orders/EditTransferredDetailsForm";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  transferDialog: {
    height: "90%",
  },
  voidTransferBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    "& button": {
      margin: "10px",
    },
  },
  tr: {
    "&:hover": {
      backgroundColor: "#fef8fa",
    },
  },
  trEven: {
    backgroundColor: "#f6f6f6",
    "&:hover": {
      backgroundColor: "#fef8fa",
    },
  },
  paper: {
    width: "100%",
    height: "calc(75vh)",
    marginTop: theme.spacing.unit * 3,
    overflow: "scroll",
  },
  table: {
    minWidth: 700,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  },
  headerBtn: {
    marginRight: "1rem",
    marginBottom: ".5rem",
  },
  notes: {
    cursor: "pointer"
  }
});

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      editingOrderId: null,
      receiptNo: "",
      isReceiptFormOpen: false,
      isSubmitting: false,
      csvReport: null,
      isGeneratingReport: false,
      isSendingNotif: false,
      isResendingNotif: false,
      isNoteFormOpen: false,
      isEditNoteFormOpen: false,
      isResendFormOpen: false,
      orderNotes: "",
      isLoading: true,
      lastEvaluatedKey: null,
      isOrderDetailsOpen: false,
      purchaseStartDate: "",
      purchaseEndDate: "",
      gameStartDate: "",
      gameEndDate: "",
      sellerEmail: "",
      sellerPhone: "",
      buyerEmail: "",
      buyerPhone: "",
      orderNumber: "",
      isPaid: "",
      isSent: "",
      isRefunded: "",
      teamSelected: "",
      isResendingReceipt: false,
      emailToResendTo: "",
      limit: 25,
      isTransferReportOpen: false,
      viewingOrder: null,
      copyText: "Copy email to clipboard?",
      numberPrevOrder: "0",
      isTEConfOpen: false,
      teListingDetails: null,
      loadingTePurchase: false,
      isSeatInfoOpen: false,
      isLoadingUser: false,
      sellers: [],
      selectedSeller: null,
      orderToEditNote: null,
      isTransferredDetailsFormOpen: false,
      isEditTransferredDetailsFormOpen: false,
      orderToSend: null,
      isTeSelected: false,
      selectedTeOrders: [],
      isBulkPay: false,
      selectedOrderIds: []
    };

    this.load50More = this.load50More.bind(this);
    this.load500More = this.load500More.bind(this);
    this.load2500More = this.load2500More.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.setOrderPrevTxns = this.setOrderPrevTxns.bind(this);
    this.resendReceipt = this.resendReceipt.bind(this);
    this.sendTransfer = this.sendTransfer.bind(this);
    this.voidTransfer = this.voidTransfer.bind(this);
    this.handleTEConfOpen = this.handleTEConfOpen.bind(this);
    this.handleTEPurchase = this.handleTEPurchase.bind(this);
    this.saveOrderNotes = this.saveOrderNotes.bind(this);
    this.updateOrderNotes = this.updateOrderNotes.bind(this);
    this.verifyBuyerEmail = this.verifyBuyerEmail.bind(this);
    this.unverifyBuyerEmail = this.unverifyBuyerEmail.bind(this);
  }

  _fetchBuyerPrevOrders = async (buyerEmail) => {
    const { orders, lastEvaluatedKey } = await API.get(
      "v2",
      `marketplace/orders/search?buyerEmail=${buyerEmail}&limit=50`
    )
      .then((data) => {
        this.setState({ ...this.state, isLoadingPrevTrx: false });
        return data;
      })
      .catch((e) => {
        this.setState({ ...this.state, isLoadingPrevTrx: false });
        alert(`error: ${JSON.stringify(e.response)}`);
      });
    const isMoreThan50 = lastEvaluatedKey
      ? `more than ${orders.length}`
      : `${orders.length}`;
    const numberOfOrder = orders.length > 1 ? isMoreThan50 : "0";
    this.setState({ ...this.state, numberPrevOrder: numberOfOrder });
  };

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const params = this.buildQueryParams();

    const { orders, lastEvaluatedKey } = await API.get(
      "v2",
      `marketplace/orders/search?${params}&limit=50`
    );

    const sorted = _.orderBy(orders, ["createdAt"], ["desc"]);
    this.setState({
      orders: sorted,
      isLoading: false,
      lastEvaluatedKey,
    });
  }

  parseDateString(dateInput) {
    const parts = dateInput.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]).valueOf();
  }

  buildQueryParams() {
    const {
      isSent,
      isPaid,
      isRefunded,
      purchaseEndDate,
      purchaseStartDate,
      gameStartDate,
      gameEndDate,
      teamSelected,
      sellerEmail,
      sellerPhone,
      buyerEmail,
      buyerPhone,
      orderNumber,
    } = this.state;
    var params = "";
    if (purchaseEndDate) {
      const date = this.parseDateString(purchaseEndDate);
      params += `&endDate=${date}`;
    }
    if (purchaseStartDate) {
      const date = this.parseDateString(purchaseStartDate);
      params += `&startDate=${date}`;
    }
    if (gameStartDate) {
      const date = this.parseDateString(gameStartDate);
      params += `&gameStartDate=${date}`;
    }
    if (gameEndDate) {
      const date = this.parseDateString(gameEndDate);
      params += `&gameEndDate=${date}`;
    }
    if (sellerEmail) {
      params += `&sellerEmail=${String(sellerEmail).replaceAll(" ", "").toLowerCase()}`;
    }
    if (sellerPhone) {
      params += `&sellerPhone=${encodeURIComponent(`+1${sellerPhone}`)}`;
    }
    if (buyerEmail) {
      params += `&buyerEmail=${String(buyerEmail).replaceAll(" ", "").toLowerCase()}`;
    }
    if (buyerPhone) {
      params += `&buyerPhone=${encodeURIComponent(`+1${buyerPhone}`)}`;
    }
    if (teamSelected) {
      params += `&teamSlug=${teamSelected}`;
    }
    if (isSent) {
      params += `&isSent=${isSent}`;
    }
    if (isPaid) {
      params += `&isPaid=${isPaid}`;
    }
    if (isRefunded) {
      params += `&isRefunded=${isRefunded}`;
    }
    if (orderNumber) {
      params += `&orderNumber=${orderNumber}`;
    }
    return params.substring(1);
  }

  async load50More() {
    try {
      this.setState({ isLoading: true, csvReport: null });
      const params = this.buildQueryParams();
      const { lastEvaluatedKey } = this.state;
      if (lastEvaluatedKey === null) {
        alert(
          "No more orders to load. To see older orders, please expand the date range."
        );
        this.setState({ isLoading: false });
        return;
      }
      const response = await API.get(
        "v2",
        `marketplace/orders/search?${params}${lastEvaluatedKey
          ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}&`
          : ""
        }&limit=50`
      );

      const newOrders = [...this.state.orders, ...response.orders];

      this.setState({
        orders: newOrders,
        lastEvaluatedKey: response.lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async load500More() {
    try {
      this.setState({ isLoading: true, csvReport: null });
      const params = this.buildQueryParams();
      const { lastEvaluatedKey } = this.state;
      if (lastEvaluatedKey === null) {
        alert(
          "No more orders to load. To see older orders, please expand the date range."
        );
        this.setState({ isLoading: false });
        return;
      }
      const response = await API.get(
        "v2",
        `marketplace/orders/search?${params}${lastEvaluatedKey
          ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}&`
          : ""
        }&limit=500`
      );

      const newOrders = [...this.state.orders, ...response.orders];

      this.setState({
        orders: newOrders,
        lastEvaluatedKey: response.lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async load2500More() {
    try {
      this.setState({ isLoading: true, csvReport: null });
      const params = this.buildQueryParams();
      const { lastEvaluatedKey } = this.state;
      if (lastEvaluatedKey === null) {
        alert(
          "No more orders to load. To see older orders, please expand the date range."
        );
        this.setState({ isLoading: false });
        return;
      }
      const response = await API.get(
        "v2",
        `marketplace/orders/search?${params}${lastEvaluatedKey
          ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}&limit=2500`
          : ""
        }`
      );

      const newOrders = [...this.state.orders, ...response.orders];

      this.setState({
        orders: newOrders,
        lastEvaluatedKey: response.lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  copyEmail = (event) => {
    const copyText = event.target.innerHTML;
    navigator.clipboard.writeText(copyText);
    this.setState({
      ...this.state,
      copyText: `Copied ${copyText} to clipboard!`,
    });
  };

  handleTooltipClose = () => {
    this.setState({ ...this.state, copyText: "Copy email to clipboard?" });
  };

  handleTooltipOrder = () => {
    this.setState({
      ...this.state,
      copyText: "Copy order number to clipboard?",
    });
  };

  getDateEmailSent = (dateSent, emailSent)  => {
    let date = dateSent ? moment.tz(dateSent, "America/Edmonton").format("MM/DD/YYYY") : '-';
    let email = emailSent ? emailSent : '-';
    return `${date}\n${email}`;
  }

  getDatePaid = (paidDate) => {
    let date = paidDate ? moment.tz(paidDate, "America/Edmonton").format("MM/DD/YYYY") : '-';
    return `${date}`;
  };

  async getOrders(e) {
    e.preventDefault();
    const params = this.buildQueryParams();
    try {
      this.setState({
        isLoading: true,
        isTeSelected: false
      });
      const { orders, lastEvaluatedKey } = await API.get(
        "v2",
        `marketplace/orders/search?${params}&limit=50`
      );

      this.setState({
        orders: _.orderBy(orders, ["createdAt"], ["desc"]),
        lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  orders(lastEvaluatedKey, limit = 50) {
    return API.get(
      "v2",
      `marketplace/orders/search?limit=${limit}${lastEvaluatedKey
        ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}`
        : ""
      }`
    );
  }

  issueSellerFail(id) {
    const { orders } = this.state;
    const { currUser } = this.props;

    const newOrders = orders.map((order) => {
      if (order.id === id) {
        order.isSellerFail = true;
      }

      return order;
    });

    this.setState({ orders: newOrders });

    API.post("v2", `marketplace/orders/issue-seller-fail?orderId=${id}`, {
      headers: { email: currUser.email }
    });
  }

  refundOrder(id) {
    const { orders } = this.state;
    const { currUser } = this.props;

    const newOrders = orders.map((order) => {
      if (order.id === id) {
        order.isRefunded = true;
        order.refundedDate = Date.now();
      }

      return order;
    });

    this.setState({ orders: newOrders });

    API.post("v2", `marketplace/orders/refund?orderId=${id}`, {
      headers: { email: currUser.email }
    });
  }

  async resendNotif(id) {
    const { currUser } = this.props;
    try {
      this.setState({
        isResendingNotif: id,
      });

      await API.post(
        "v2",
        `marketplace/orders/resend-seller-notification?orderId=${id}`,
        { headers: { email: currUser.email } }
      );

      this.setState({
        isResendingNotif: null,
      });
    } catch (e) {
      this.setState({
        isResendingNotif: null,
      });
      alert(`error: ${e.response.data.message}`);
    }
  }

  async sendNotif(id) {
    const { currUser } = this.props;
    try {
      this.setState({
        isSendingNotif: id,
      });

      await API.post(
        "v2",
        `marketplace/orders/resend-seller-notification?orderId=${id}`,
        { headers: { email: currUser.email } }
      );

      const newOrders = this.state.orders.map((order) => {
        if (order.id === id) {
          return {
            ...order,
            ...{
              isSellerNotificationSent: true,
            },
          };
        }

        return order;
      });

      this.setState({
        isSendingNotif: null,
        orders: newOrders,
      });
    } catch (e) {
      this.setState({
        isSendingNotif: null,
      });
      alert(`error: ${e.response.data.message}`);
    }
  }

  async resendReceipt({ id, email }) {
    this.setState({ isSubmitting: true });
    const { currUser } = this.props;

    try {
      await API.post("v2", `marketplace/orders/${id}/resend-receipt`, {
        headers: { email: currUser.email },
        body: {
          email,
        },
      });

      this.setState({
        orderToResend: null,
        isResendFormOpen: false,
        emailToResendTo: "",
        isSubmitting: false,
      });
      CDialog.success("Success!", "Email resent.");
    } catch (e) {
      this.setState({
        orderToResend: null,
        isResendFormOpen: false,
        emailToResendTo: "",
        isSubmitting: false,
      });
      CDialog.error("Error!", "Failed to resent the email");
    }
  }

  async markAsPaid(id) {
    const { orders } = this.state;
    const { currUser } = this.props;

    this.setState({
      isSubmitting: true,
    });

    const receiptNo = moment().format("MM/DD/YY");

    try {
      await API.post("v2", `marketplace/orders/mark-paid?orderId=${id}`, {
        headers: { email: currUser.email }
      });

      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.isPaid = true;
          order.receiptNo = receiptNo;
        }

        return order;
      });

      this.setState({
        orders: newOrders,
        editingOrder: null,
        isReceiptFormOpen: false,
        receiptNo: "",
        isSubmitting: false,
      });
    } catch (e) {
      this.setState({
        editingOrder: null,
        isReceiptFormOpen: false,
        receiptNo: "",
        isSubmitting: false,
      });
    }
  }

  async bulkPay() {
    const { orders, selectedOrderIds } = this.state;
    const { currUser } = this.props;

    this.setState({
      isSubmitting: true,
    });
    const updatedIds = [];
    const failedIds = [];
    await Promise.all(
      selectedOrderIds.map(async id => {
        try {
          await API.post("v2", `marketplace/orders/mark-paid?orderId=${id}`, {
            headers: { email: currUser.email }
          });
          updatedIds.push(id);
        } catch (e) {
          failedIds.push(id);
        }
      })
    );

    if (updatedIds.length > 0) {
      const receiptNo = moment().format("MM/DD/YY");
      const newOrders = orders.map((order) => {
        if (updatedIds.includes(order.id)) {
          order.isPaid = true;
          order.receiptNo = receiptNo;
        }
        return order;
      });

      this.setState({
        orders: newOrders,
        editingOrder: null,
        isReceiptFormOpen: false,
        receiptNo: "",
        isSubmitting: false,
        isBulkPay: false,
        selectedOrderIds: []
      });

      if (failedIds.length > 0) {
        CDialog.warning("Warning", `${failedIds.length} order(s) failed to pay.`);
      }
    } else {
      this.setState({
        isSubmitting: false,
      });
      CDialog.error("Error", "Failed to pay.");
    }
  }

  async markTEAsPaid() {
    const { orders, selectedTeOrders } = this.state;
    const { currUser } = this.props;

    this.setState({
      isSubmitting: true,
    });

    const receiptNo = moment().format("MM/DD/YY");

    try {
      await API.post("v2", `marketplace/orders/bulk-mark-te-paid`, {
        headers: { email: currUser.email },
        body: { ids: selectedTeOrders }
      });

      const newOrders = orders.map((order) => {
        if (selectedTeOrders.includes(order.id)) {
          order.isPaid = true;
          order.receiptNo = receiptNo;
        }

        return order;
      });

      this.setState({
        orders: newOrders,
        isSubmitting: false,
        isTeSelected: false,
        selectedTeOrders: []
      });
    } catch (e) {
      this.setState({ isSubmitting: false });
      CDialog.error("Error", "Failed to update the data");
    }
  }

  async markAsCancel(id) {
    const { orders } = this.state;
    const { currUser } = this.props;
    this.setState({
      isSubmitting: true,
    });

    try {
      await API.post("v2", `marketplace/orders/sales-cancel?orderId=${id}`, {
        headers: { email: currUser.email }
      });
      
      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.isSaleCancel = true;
        }
        return order;
      });

      this.setState({
        orders: newOrders,
        orderToCancel: null,
        isSalesCancelConfOpen: false,
        isSubmitting: false,
      });
    } catch (e) {
      this.setState({
        orderToCancel: null,
        isSalesCancelConfOpen: false,
        isSubmitting: false,
      });
    }
  }

  async verifyBuyerEmail(order) {
    this.setState({ isLoading: true });
    const { orders } = this.state;
    const { currUser } = this.props;

    try {
      await API.post("v2", `marketplace/accounts/verify-buyer-email`, {
        headers: { email: currUser.email },
        body: {
          emailAddress: order.email,
        },
      });

      const newOrders = orders.map((item) => {
        if (item.email === order.email) {
          item.emailVerified = true;
        }
        return item;
      });

      this.setState({
        orders: newOrders,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to verify the email");
    }
  }

  async unverifyBuyerEmail(order) {
    this.setState({ isLoading: true });
    const { orders } = this.state;
    const { currUser } = this.props;

    try {
      await API.post("v2", `marketplace/accounts/unverify-buyer-email`, {
        headers: { email: currUser.email },
        body: {
          emailAddress: order.email,
        },
      });

      const newOrders = orders.map((item) => {
        if (item.email === order.email) {
          item.emailVerified = false;
        }
        return item;
      });

      this.setState({
        orders: newOrders,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to unverify the email");
    }
  }

  handleReceiptNoChange = (e) => {
    this.setState({
      receiptNo: e.target.value,
    });
  };

  handleEmailToResendChange = (e) => {
    this.setState({
      emailToResendTo: e.target.value,
    });
  };

  handleOrderNotesChange = (e) => {
    this.setState({
      orderNotes: e.target.value,
    });
  };

  openReceiptForm = (order) => {
    this.setState({
      editingOrder: order,
      isReceiptFormOpen: true,
      receiptNo: "",
    });
  };

  openNotesForm = (order) => {
    this.setState({
      orderToAddNote: order,
      isNoteFormOpen: true,
      orderNotes: "",
    });
  };

  openEditNotesForm = (order) => {
    this.setState({
      orderToEditNote: order,
      isEditNoteFormOpen: true,
    });
  };

  async voidTransfer() {
    const { viewingOrder } = this.state;
    const orderId = viewingOrder.id;
    const transferId = viewingOrder.transfer.id;
    try {
      await API.post(
        "v2",
        `admin/operations/transfers/void?transferId=${transferId}&orderId=${orderId}`
      );
      alert("Transfer voided");
    } catch (e) {
      alert(`Transfer failed to void`);
    }
  }

  async sendTransfer() {
    const { viewingOrder, orders } = this.state;
    const orderId = viewingOrder.id;
    const transferId = viewingOrder.transfer.id;
    try {
      await API.post(
        "v2",
        `admin/operations/transfers/send?transferId=${transferId}&orderId=${orderId}`
      );
      alert("Transfer sent");

      const newOrders = orders.map((order) => {
        if (order.id === orderId) {
          order.transfer.sent = true;
        }

        return order;
      });

      this.setState({ orders: newOrders });
    } catch (e) {
      alert(`Transfer failed to send`);
    }
  }

  handleFormClose = () => {
    this.setState({
      editingOrder: null,
      isReceiptFormOpen: false,
      isResendFormOpen: false,
      receiptNo: "",
    });
  };

  handleTransferFormOpenClose = (order) => {
    this.setState({
      isTransferReportOpen: !this.state.isTransferReportOpen,
      viewingOrder: order,
    });
  };  

  renderReceiptForm() {
    const { isReceiptFormOpen, editingOrder, isSubmitting } = this.state;

    return (
      <Dialog
        open={isReceiptFormOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mark Order as Paid</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Enter receipt no. for {editingOrder && editingOrder.description} by{" "}
            {editingOrder && editingOrder.name}
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Receipt No."
            type="receiptNo"
            onChange={this.handleReceiptNoChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={this.handleFormClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => this.markAsPaid(editingOrder.id)}
            color="primary"
          >
            Mark as Paid
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderResendReceipt() {
    const { isResendFormOpen, orderToResend, isSubmitting } = this.state;

    if (!isResendFormOpen && !orderToResend) {
      return null;
    }
    return (
      <Dialog
        open={isResendFormOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Resend Email Receipt</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "1rem" }}>
            <Typography variant="body1">
              {orderToResend.game.shortName}{" "}
              {moment
                .tz(orderToResend.game.date, orderToResend.game.timezone)
                .format("MMM DD YYYY hh:mm A")}
            </Typography>
            <Typography variant="body1">
              {orderToResend.name} - {orderToResend.email}
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Email Address to send to"
            type="receiptNo"
            defaultValue={orderToResend.email}
            onChange={this.handleEmailToResendChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={this.handleFormClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() =>
              this.resendReceipt({
                id: orderToResend.id,
                email: this.state.emailToResendTo
                  ? this.state.emailToResendTo
                  : orderToResend.email,
              })
            }
            color="primary"
          >
            Resend Email Receipt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleNotesFormClose = () => {
    this.setState({
      orderToAddNote: null,
      isNoteFormOpen: false,
      orderNotes: "",
    });
  }

  handleEditNotesFormClose = () => {
    this.setState({
      orderToEditNote: null,
      isEditNoteFormOpen: false,
    });
  }

  openTransferredDetailsForm = (order) => {
    this.setState({
      orderToSend: order,
      isTransferredDetailsFormOpen: true
    });
  };

  handleTransferredDetailsFormClose = () => {
    this.setState({
      orderToSend: null,
      isTransferredDetailsFormOpen: false
    });
  }

  handleSuccessSent = (orderResponse) => {
    const newOrders = this.state.orders.map((order) => {
      if (order.id === orderResponse.id) {
        order.isSent = true;
        order.dateSent = orderResponse.dateSent;
        order.emailSent = orderResponse.emailSent;
        order.transferredDetails = orderResponse.transferredDetails;
        order.isPaid = orderResponse.isPaid;
        order.receiptNo = orderResponse.receiptNo;
      }
      return order;
    });

    this.setState({
      orders: newOrders,
      orderToSend: null,
      isTransferredDetailsFormOpen: false
    });
  }

  openEditTransferredDetailsForm = (order) => {
    this.setState({
      orderToSend: order,
      isEditTransferredDetailsFormOpen: true
    });
  };

  handleEditTransferredDetailsFormClose = () => {
    this.setState({
      orderToSend: null,
      isEditTransferredDetailsFormOpen: false
    });
  }

  handleSuccessEditTransferredDetail = (orderResponse) => {
    const newOrders = this.state.orders.map((order) => {
      if (order.id === orderResponse.id) {
        order.transferredDetails = orderResponse.transferredDetails;
      }
      return order;
    });

    this.setState({
      orders: newOrders,
      orderToSend: null,
      isEditTransferredDetailsFormOpen: false
    });
  }

  async saveOrderNotes(id) {
    try {
      const { orders, orderNotes } = this.state;
      const { currUser } = this.props;
      this.setState({ isSubmitting: true });

      await API.post("v2", `marketplace/orders/notes/add`, {
        headers: { email: currUser.email },
        body: { orderId: id, note: orderNotes },
      });

      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.notes = order.notes
            ? order.notes.concat(orderNotes)
            : [orderNotes];
        }

        return order;
      });

      this.setState({ orders: newOrders });

      this.setState({ isSubmitting: false });

      this.handleNotesFormClose();
    } catch (e) {
      this.setState({ isSubmitting: false });

      this.handleNotesFormClose();
    }
  }

  async updateOrderNotes(id, notes) {
    try {
      const { orders } = this.state;
      const { currUser } = this.props;
      this.setState({ isSubmitting: true });

      await API.post("v2", `marketplace/orders/notes/update`, {
        headers: { email: currUser.email },
        body: { orderId: id, notes },
      });

      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.notes = notes;
        }

        return order;
      });

      this.setState({
        orders: newOrders,
        isSubmitting: false
      });

      this.handleEditNotesFormClose();
    } catch (e) {
      this.setState({ isSubmitting: false });
      CDialog.error("Error", "Internal Server Error");
      this.handleEditNotesFormClose();
    }
  }

  setOrderPrevTxns(id, transactions) {
    const newOrders = this.state.orders.map((order) => {
      if (order.id === id) {
        return {
          ...order,
          ...{
            previousTransactions: transactions,
          },
        };
      }

      return order;
    });

    this.setState({
      orders: newOrders,
    });
  }

  openOrderDetails(order) {
    this._fetchBuyerPrevOrders(order.email);
    this.setState({
      isOrderDetailsOpen: true,
      numberPrevOrder: "0",
      isLoadingPrevTrx: true,
      orderToView: order,
    });
  }

  renderOrderDetails() {
    const { isOrderDetailsOpen, orderToView, numberPrevOrder, isLoadingPrevTrx, isLoading } = this.state;
    const { currUser } = this.props;
    const { userGroup } = currUser;

    if (!isOrderDetailsOpen) {
      return;
    }

    return (
      <Dialog
        open={isOrderDetailsOpen}
        onClose={() => this.setState({ isOrderDetailsOpen: false })}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Order Details</DialogTitle>
        <DialogContent style={{ marginBottom: "2rem" }}>
          <OrderDetails
            order={orderToView}
            numberPrevOrder={numberPrevOrder}
            isLoadingPrevTrx={isLoadingPrevTrx}
            isLoading={isLoading}
            allowAction={isAllowedAction(userGroup)}
            unverifyBuyerEmail={this.unverifyBuyerEmail}
            verifyBuyerEmail={this.verifyBuyerEmail}
          />
        </DialogContent>
      </Dialog>
    );
  }

  team(slug) {
    const abbreviation = getTeamAbbreviation(slug);
    return abbreviation ? abbreviation : "";
  }

  teamLongName(slug) {
    const teamName = getTeamName(slug);
    return teamName ? teamName : "";
  }

  async handleTEConfOpen(order) {
    this.setState({ isTEConfOpen: true });
    await API.get(
      "v2",
      `marketplace/ticketEvo/listings/show?listingId=${order.teListingID}`
    )
      .then((data) => {
        this.setState({
          teListingDetails: data,
          viewingOrder: order,
        });
      })
      .catch((e) => {
        alert(
          `Could not find Ticket Evolution listing for ID: ${order.teListingID}`
        );
        this.setState({ isTEConfOpen: false });
      });
  }

  getUser(email) {
    this.setState({ isLoadingUser: true });
    API.get(
      "v2",
      `marketplace/users/by/email/${email}?includeTotalSellerOrders=true`
    )
      .then((data) => {
        if (data.user) {
          this.setState({
            isLoadingUser: false,
            selectedSeller: data.user,
            sellers: [...this.state.sellers, data.user],
          });
        } else {
          this.setState({ isLoadingUser: false });
        }
      })
      .catch((e) => {
        this.setState({ isLoadingUser: false });
      });
  }

  openSeatInfo = async (order) => {
    var seller = this.state.sellers.find(item => item.email === order.seat.sellerEmail);
    this.setState({
      viewingOrder: order,
      selectedSeller: isNil(seller) ? null : seller,
      isSeatInfoOpen: true,
    });

    if (isNil(seller)) {
      this.getUser(order.seat.sellerEmail);
    }
  };

  closeSeatInfo = () => {
    this.setState({
      viewingOrder: null,
      selectedSeller: null,
      isSeatInfoOpen: false,
    });
  };

  renderOrders() {
    const { classes } = this.props;

    if (this.state.orders && this.state.orders.length === 0) {
      return (
        <Typography
          align="center"
          color="textSecondary"
          variant="headline"
          style={{ marginTop: "5rem" }}
        >
          No orders for this date.
        </Typography>
      );
    }

    return (
      this.state.orders &&
      this.state.orders.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {this.state.isTeSelected && (
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={e => this.selectAllTERow(e)}
                    checked={this.state.orders.length === this.state.selectedTeOrders.length}
                  />
                </TableCell>)
              }
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Order Number
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Add Note
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Notes
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Team
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
              >
                Purchase Date
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Name
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Email
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Verified
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Game
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Game Date
              </TableCell>
              {this.state.isBulkPay && (
                <TableCell padding="none"></TableCell>
              )}
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Seat
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                No of Seats
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Total
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Seller Email
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Resend Notif
              </TableCell>
              {/* <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Trasnfer Status
              </TableCell> */}
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Ticket Sent
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Paid
              </TableCell>
              {/* <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Resend Notif
              </TableCell> */}
              {/* <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Trasnfer Status
              </TableCell> */}
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Resend Confirmation
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Refunded
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Seller Fail
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Sale Cancel
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Buy TE Listing
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.orders.map((order, key) => {
              const { isRefunded } = this.state;
              if (!order.seat) {
                return null;
              }
              if (isRefunded === "false" && order.isRefunded) {
                return null;
              }
              if (isRefunded === "true" && !order.isRefunded) {
                return null;
              }

              const { game } = order;
              let gameName = "-";
              let gameDate = "-";
              let rowName =`${order.seat.row}`; 

              if (game) {
                gameName = `${game.opponent} at ${this.teamLongName(
                  game.homeTeamSlug
                )}`;

                gameDate = game.isTbd
                  ? "TBD"
                  : moment
                    .tz(order.game.date, order.game.timezone)
                    .format("MM/DD/YYYY h:mm A");
                
                rowName = renderRowName(order.seat.zone, order.seat.row, game.homeTeamSlug, game.isSpecial);
              }

              const sellerEmail =
                order.seat.sellerEmail && order.seat.sellerEmail !== "-"
                  ? String(order.seat.sellerEmail).trim()
                  : "-";

              return (
                <TableRow
                  key={order.id}
                  className={key % 2 === 0 ? classes.trEven : classes.tr}
                >
                  {this.state.isTeSelected && (
                    <TableCell padding="checkbox">
                      {order.seat.sellerEmail === "te@fansfirst.ca" && (
                        <Checkbox onChange={e => this.selectTERow(e, order.id)} checked={this.isTESelected(order.id)} />
                      )}
                    </TableCell>
                  )}
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Tooltip
                      disableFocusListener
                      title={this.state.copyText}
                      onOpen={this.handleTooltipOrder}
                      onClose={this.handleTooltipOrder}
                    >
                      <span
                        id={`${order.id}-${order.trackingNumber}`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.copyEmail(e)}
                      >
                        {order.trackingNumber ? order.trackingNumber : "-"}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => this.openNotesForm(order)}
                    >
                      Add Note
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <div onClick={() => this.openEditNotesForm(order)} className={classes.notes}>
                      {order.notes && order.notes.length > 0 ? <OrderNotes notes={order.notes} /> : "-"}
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {game ? this.team(game.homeTeamSlug) : "-"}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {moment
                      .tz(Number(order.createdAt), "America/Edmonton")
                      .format("MMM DD,YYYY h:mm A")}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono", cursor: "pointer" }}
                    component="th"
                    scope="row"
                    onClick={() => this.openOrderDetails(order)}
                  >
                    {order.name}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Tooltip
                      disableFocusListener
                      title={this.state.copyText}
                      onClose={this.handleTooltipClose}
                    >
                      <span
                        id={order.id}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.copyEmail(e)}
                      >
                        {String(order.email).trim()}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.emailVerified ? (
                      <CheckIcon style={{ color: green[800] }} />
                    ) : (
                      <CloseIcon style={{ color: red[800] }} />
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {gameName}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {gameDate}
                  </TableCell>
                  {this.state.isBulkPay && (
                    <TableCell padding="none">
                      {!order.isPaid && !order.isRefunded && (
                        <Checkbox
                          onChange={e => this.checkSeat(e, order.id)}
                          checked={this.state.selectedOrderIds.includes(order.id)} 
                        />
                      )}                      
                    </TableCell>
                  )}
                  <TableCell
                    style={{ fontFamily: "Roboto Mono", cursor: "pointer" }}
                    padding="checkbox"
                    onClick={() => this.openSeatInfo(order)}
                  >
                    {getZoneLabel(order.seat)}
                    {" "}
                    Row {rowName} {order.isAisleSeat ? "Aisle" : ""}{" "}
                    {order.seat.isObstructedView ? "Obstructed View" : ""}{" "}
                    {order.isInstantDelivery ? (
                      <strong style={{ color: "#ff1744" }}>Insta</strong>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.noOfSeats}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    CAD$ {(order.ticketPrice * order.noOfSeats).toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Tooltip
                      disableFocusListener
                      title={this.state.copyText}
                      onClose={this.handleTooltipClose}
                    >
                      <span
                        id={order.id}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.copyEmail(e)}
                      >
                        {sellerEmail}
                      </span>
                    </Tooltip>
                    {order.isFirstSale && (
                      <div><strong style={{ color: "#ff1744" }}>FIRST SALE</strong></div>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {!order.isSellerNotificationSent ? (
                      <Button
                        color={order.isRefunded ? null : "secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.sendNotif(order.id)}
                        disabled={this.state.isSendingNotif === order.id}
                      >
                        {this.state.isSendingNotif === order.id
                          ? "Sending..."
                          : "Send Notif"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => this.resendNotif(order.id)}
                        disabled={this.state.isResendingNotif === order.id}
                        style={{ fontWeight: "700" }}
                      >
                        {this.state.isResendingNotif === order.id
                          ? "Sending..."
                          : "Resend Notif"}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isSent ? (
                      <Tooltip
                        disableFocusListener
                        title={
                          <div>
                            <div style={{ fontSize: 14, whiteSpace: 'pre', lineHeight: 1.5 }}>
                              {this.getDateEmailSent(order.dateSent, order.emailSent).split('\n').map((value, index) => (
                                <span key={index}>{value}<br /></span>
                              ))}
                            </div>
                            Click to see the detail
                          </div>
                        }
                      >
                        <span style={{ cursor: "context-menu" }}>
                          <Button variant="contained" size="small" onClick={() => this.openEditTransferredDetailsForm(order)}>
                            Sent
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        color={order.isRefunded ? null : "secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.openTransferredDetailsForm(order)}
                        disabled={order.isRefunded}
                      >
                        Send
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isPaid ? (
                      <Tooltip
                        disableFocusListener
                        title={
                          <div style={{ fontSize: 14, whiteSpace: 'pre', lineHeight: 1.5 }}>
                            {this.getDatePaid(order.seat.datePaid).split('\n').map((value, index) => (
                              <span key={index}>{value}<br /></span>
                            ))}
                          </div>
                        }
                      >
                        <span
                          id={order.id}
                          style={{ cursor: "context-menu" }}
                        >
                          <Button disabled={true} variant="contained" size="small">
                            Paid
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        color={order.isRefunded ? null : "secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.markAsPaid(order.id)}
                        disabled={order.isRefunded}
                      >
                        Pay
                      </Button>
                    )}
                  </TableCell>
                  {/* <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.transfer && order.transfer.sent ? (
                      <Button
                        color={"secondary"}
                        variant="contained"
                        size="small"
                        disabled={true}
                      >
                        Transfer Status
                      </Button>
                    ) : (
                      <Button
                        color={order.isRefunded ? "" : "secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.handleTransferFormOpenClose(order)}
                      >
                        Transfer Status
                      </Button>
                    )}
                  </TableCell> */}
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Button
                      color={order.isRefunded ? null : "secondary"}
                      variant="contained"
                      size="small"
                      onClick={() =>
                        this.setState({
                          isResendFormOpen: true,
                          orderToResend: order,
                        })
                      }
                      disabled={this.state.isResendingReceipt === order.id}
                    >
                      Resend Confirmation
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isRefunded ? (
                      <Button disabled={true} variant="contained" size="small">
                        Refunded ({moment(order.dateRefunded).format("MM/DD")})
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => this.refundOrder(order.id)}
                      >
                        Refund
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isSellerFail ? (
                      <Button disabled={true} variant="contained" size="small">
                        Seller Fail Issued
                      </Button>
                    ) : (
                      <Button
                        color={order.isRefunded ? null : "secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.issueSellerFail(order.id)}
                      >
                        Issue Seller Fail
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isSaleCancel ? (
                      <Button disabled={true} variant="contained" size="small">
                        Sale Cancelled
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() =>
                          this.setState({
                            isSalesCancelConfOpen: true,
                            orderToCancel: order,
                          })
                        }
                      >
                        Sale Cancel
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {sellerEmail === "te@fansfirst.ca" ? (
                      <Button
                        variant="contained"
                        size="small"
                        color={"secondary"}
                        onClick={() => this.handleTEConfOpen(order)}
                        disabled={order.teIsPurchased}
                      >
                        BUY
                      </Button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )
    );
  }

  formatOrders(orders) {
    return new Promise(async (resolve, reject) => {
      const mapped = orders.map((order) => {
        return new Promise((resolve, reject) => {
          const { game, seat } = order;

          let gameDate = "-";

          if (game) {

            gameDate = game.isTbd
              ? "TBD"
              : moment
                .tz(order.game.date, order.game.timezone)
                .format("MMMM D, YYYY h:mm A");
          }

          resolve({
            Notes: order.notes,
            Team: this.team(game.homeTeamSlug),
            "Purchase Date": moment.tz(order.createdAt, "America/Edmonton").format("MMMM D, YYYY h:mm A"),
            Name: order.name,
            Email: order.email,
            Game: `${game.opponent} at ${this.teamLongName(game.homeTeamSlug)}`,
            "Game Date": gameDate,
            Seat: seat && `${seat.zone} ${seat.zoneNo} Row ${seat.row}`,
            "No of Seats": order.noOfSeats,
            Total: (order.ticketPrice * order.noOfSeats).toFixed(2),
            "Seller Email": seat && seat.sellerEmail,
            Sent: order.isSent ? "Sent" : "Send",
            Paid: order.isPaid ? "Paid" : "Pay",
            Refunded: order.isRefunded ? "Refunded" : "Refund",
          });
        });
      });

      const resolved = await Promise.all(mapped);
      const sorted = _.sortBy(resolved, "date", "desc");

      resolve(sorted);
    });
  }

  async generateReport() {
    this.setState({
      isGeneratingReport: true,
    });

    const { orders } = this.state;

    const formattedOrders = await this.formatOrders(orders);

    this.setState({
      csvReport: formattedOrders,
      isGeneratingReport: false,
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleStatusChange(e) {
    const orderStatus = e.target.value;

    this.setState({
      orderStatus,
    });
  }

  handleTeamChange(e) {
    const teamSelected = e.target.value;

    this.setState({
      teamSelected,
    });
  }

  async handleTEPurchase() {
    const { teListingDetails, viewingOrder, orders } = this.state;
    const { currUser } = this.props;
    const seatZone = viewingOrder.seat.zone ? viewingOrder.seat.zone : '-';
    const zoneValue = viewingOrder.seat.zoneNo ? viewingOrder.seat.zoneNo : seatZone;
    const data = {
      sellerId: teListingDetails.office.id,
      price: teListingDetails.retail_price,
      quantity: viewingOrder.noOfSeats,
      ticketGroupId: teListingDetails.id,
      orderType: teListingDetails.format,
      gameName: viewingOrder.seat.gameName,
      zone: zoneValue || '-',
      row: viewingOrder.seat.row,
      orderId: viewingOrder.id,
      email: viewingOrder.email,
      name: viewingOrder.name,
    };
    try {
      this.setState({
        loadingTePurchase: true,
      });
      const te_orders = await API.post(
        "v2",
        "marketplace/ticketEvo/admin/orders/create",
        {
          headers: { email: currUser.email },
          body: data,
        }
      );
      if (te_orders && te_orders["orders"] && te_orders["orders"].length > 0) {
        const newOrders = orders.map((order) => {
          if (order.id === viewingOrder.id) {
            order.teIsPurchased = true;
            order.notes = order.notes
              ? order.notes.concat(`TE ${te_orders["orders"][0]["oid"]}`)
              : [`TE ${te_orders["orders"][0]["oid"]}`];
            order.isSellerNotificationSent = true;
          }
          return order;
        });
        this.setState({
          isTEConfOpen: false,
          teListingDetails: null,
          viewingOrder: null,
          orders: newOrders,
        });
      } else {
        // window.location.reload();
      }
    } catch (error) {
      alert(
        `Something went wrong. Please try again later. Error message: ${error.message}`
      );
    } finally {
      this.setState({ loadingTePurchase: false });
    }
  }

  selectTEOrders() {
    this.setState({
      sellerEmail: "te@fansfirst.ca",
      isPaid: "false",
      isSent: "true",
      isLoading: true
    }, async () => {
      const params = this.buildQueryParams();
      try {
        const { orders, lastEvaluatedKey } = await API.get(
          "v2",
          `marketplace/orders/search?${params}&limit=50`
        );

        this.setState({
          orders: _.orderBy(orders, ["createdAt"], ["desc"]),
          lastEvaluatedKey,
        });
        this.setState({
          isLoading: false,
          isTeSelected: true,
        });
      } catch (e) {
        this.setState({ isLoading: false });
        alert(e);
      }
    });
  }

  selectTERow(e, id) {
    if (e.target.checked) {
      this.setState({
        selectedTeOrders: [...this.state.selectedTeOrders, id]
      });
    } else {
      this.setState({
        selectedTeOrders: this.state.selectedTeOrders.filter(item => item !== id)
      });
    }
  }

  selectAllTERow(e) {
    const { checked } = e.target;
    const teOrderIds =  map(this.state.orders, "id");
    this.setState({
      selectedTeOrders: checked ? teOrderIds : []
    });
  }

  isTESelected(id) {
    return this.state.selectedTeOrders.includes(id);
  }

  selectSeats() {
    const unpaidOrders = this.state.orders.filter(order => !order.isPaid && !order.isRefunded);
    this.setState({
      isBulkPay: true,
      selectedOrderIds: unpaidOrders.map(order => order.id)
    });
  }

  checkSeat(e, id) {
    if (e.target.checked) {
      this.setState({
        selectedOrderIds: [...this.state.selectedOrderIds, id]
      });
    } else {
      this.setState({
        selectedOrderIds: this.state.selectedOrderIds.filter(item => item !== id)
      });
    }
  }

  renderTEConfDialog() {
    const { isTEConfOpen, teListingDetails, viewingOrder, loadingTePurchase } = this.state;
    if (!isTEConfOpen) {
      return;
    }

    return (
      <Dialog
        open={isTEConfOpen}
        onClose={() => {
          this.setState({
            isTEConfOpen: false,
            teListingDetails: null,
          });
        }}
      >
        <DialogTitle>Confirm Ticket Evolution Order</DialogTitle>
        {teListingDetails ? (
          <>
            <DialogContent>
              <Typography variant="body2">
                Ticket Evolution Listing ID
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.id}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Game
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {viewingOrder.game.longName}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Game Date
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {moment
                  .tz(viewingOrder.game.date, viewingOrder.game.timezone)
                  .format("MM/DD/YYYY h:mm A")}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Section
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.section}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Row
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.row}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Quantity
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {viewingOrder.noOfSeats}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Quantities Available
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.splits.map((quantity) => (
                  <span key={quantity}>{quantity} </span>
                ))}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Price
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.retail_price}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "1rem" }}>
                Notes
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0rem" }}>
                {teListingDetails.public_notes}
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginBottom: "1em",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    isTEConfOpen: false,
                    teListingDetails: null,
                  });
                }}
              >
                Cancel
              </Button>
              {loadingTePurchase ? (
                <CircularProgress></CircularProgress>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleTEPurchase()}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1.5em",
            }}
          >
            <CircularProgress></CircularProgress>
          </div>
        )}
      </Dialog>
    );
  }

  renderSalesCancelConfDialog() {
    const { isSalesCancelConfOpen, orderToCancel, isSubmitting } = this.state;

    if (!isSalesCancelConfOpen && !orderToCancel) {
      return null;
    }

    return (
      <Dialog
        open={isSalesCancelConfOpen}
        onClose={() => {
          this.setState({
            isSalesCancelConfOpen: false,
            orderToCancel: null,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Sales Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Are you sure you want to cancel this Sales Order?
            <Typography variant="body1">
              It will notify the seller, that this order has failed.
            </Typography>
            <Typography variant="body1">
              Order Number: <b>{orderToCancel.trackingNumber}</b>
            </Typography>
            <Typography variant="body1">
              Seller Email: <b>{orderToCancel.seat.sellerEmail}</b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              this.setState({
                isSalesCancelConfOpen: false,
                orderToCancel: null,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => this.markAsCancel(orderToCancel.id)}
            color="primary"
          >
            Mark as Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, currUser } = this.props;
    const { userGroup } = currUser;
    const {
      csvReport,
      isGeneratingReport,
      isNoteFormOpen,
      isEditNoteFormOpen,
      orderToAddNote,
      orderToEditNote,
      isSubmitting,
      isSent,
      isPaid,
      isRefunded,
      isLoading,
      isTransferReportOpen,
      numberPrevOrder,
      isLoadingPrevTrx,
      purchaseEndDate,
      purchaseStartDate,
      gameStartDate,
      gameEndDate,
      teamSelected,
      sellerEmail,
      sellerPhone,
      buyerEmail,
      buyerPhone,
      orderNumber,
      viewingOrder,
      isSeatInfoOpen,
      selectedSeller,
      isLoadingUser,
      isTransferredDetailsFormOpen,
      isEditTransferredDetailsFormOpen,
      orderToSend,
      selectedTeOrders,
      selectedOrderIds
    } = this.state;

    return (
      <div className={classes.root} id="Orders">
        <Grid container>
          <Grid item xs={12} md={10}>
            <Typography variant="display2" style={{ marginBottom: "2rem" }}>
              Search Orders
            </Typography>
            <SearchForm
              isSent={isSent}
              isPaid={isPaid}
              isRefunded={isRefunded}
              purchaseEndDate={purchaseEndDate}
              purchaseStartDate={purchaseStartDate}
              gameStartDate={gameStartDate}
              gameEndDate={gameEndDate}
              teamSelected={teamSelected}
              sellerEmail={sellerEmail}
              sellerPhone={sellerPhone}
              buyerEmail={buyerEmail}
              buyerPhone={buyerPhone}
              orderNumber={orderNumber}
              isLoading={isLoading}
              handleChange={this.handleChange}
              getOrders={this.getOrders}
              classes={classes}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            {!csvReport && (
              <Button
                variant="contained"
                color="primary"
                disabled={isGeneratingReport}
                onClick={() => this.generateReport()}
                className={classes.headerBtn}
              >
                Generate Report
              </Button>
            )}
            {csvReport && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.headerBtn}
              >
                <CSVLink
                  data={csvReport}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download Report
                </CSVLink>
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => this.selectTEOrders()}
              className={classes.headerBtn}
            >
              Select Unpaid TE Orders
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => this.selectSeats()}
              className={classes.headerBtn}
            >
              Select Orders
            </Button>
          </Grid>
          <Grid item>
            {selectedTeOrders.length > 0 && (
              <>
                <Typography variant="body2" gutterBottom>
                  {selectedTeOrders.length} TE Order(s) selected
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isGeneratingReport}
                  onClick={() => this.markTEAsPaid()}
                  className={classes.headerBtn}
                >
                  Mark as Paid
                </Button>
              </>
            )}
            {selectedOrderIds.length > 0 && (
              <>
                <Typography variant="body2" gutterBottom>
                  {selectedOrderIds.length} Order(s) selected
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isGeneratingReport || isSubmitting}
                  onClick={() => this.bulkPay()}
                  className={classes.headerBtn}
                >
                  Mark as Paid
                </Button>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {/* {this.state.isLoading && (
              <Typography
                variant="headline"
                align="center"
                style={{ marginTop: '2rem' }}
              >
                Loading Orders...
              </Typography>
            )} */}
            {this.renderOrders()}
            <div className={classes.loadMoreBtns}>
              <Button
                onClick={this.load50More}
                disabled={this.state.isLoading}
                variant="contained"
                color="secondary"
                className={classes.loadMoreBtn}
              >
                Load 50 More
              </Button>
              <Button
                onClick={this.load500More}
                disabled={this.state.isLoading}
                variant="contained"
                color="secondary"
                className={classes.loadMoreBtn}
              >
                Load 500 More
              </Button>
            </div>
          </Grid>
        </Grid>
        <NoteForm
          isNoteFormOpen={isNoteFormOpen}
          orderToAddNote={orderToAddNote}
          isSubmitting={isSubmitting}
          handleNotesFormClose={this.handleNotesFormClose}
          handleOrderNotesChange={this.handleOrderNotesChange}
          saveOrderNotes={this.saveOrderNotes}
        />
        <EditNoteForm
          isDialogOpen={isEditNoteFormOpen}
          order={orderToEditNote}
          isSubmitting={isSubmitting}
          closeDialog={this.handleEditNotesFormClose}
          saveNotes={this.updateOrderNotes}
        />
        {this.renderOrderDetails()}
        {this.renderResendReceipt()}
        <TransferForm
          order={viewingOrder}
          isTransferReportOpen={isTransferReportOpen}
          numberPrevOrder={numberPrevOrder}
          isLoadingPrevTrx={isLoadingPrevTrx}
          isLoading={isLoading}
          allowAction={isAllowedAction(userGroup)}
          classes={classes}
          sendTransfer={this.sendTransfer}
          voidTransfer={this.voidTransfer}
          handleTransferFormOpenClose={this.handleTransferFormOpenClose}
          unverifyBuyerEmail={this.unverifyBuyerEmail}
          verifyBuyerEmail={this.verifyBuyerEmail}
        />
        {this.renderTEConfDialog()}
        <SeatInfo
          showDialog={isSeatInfoOpen}
          isLoadingUser={isLoadingUser}
          order={viewingOrder}
          seller={selectedSeller}
          onClose={this.closeSeatInfo}
        />
        {this.renderSalesCancelConfDialog()}
        <TransferredDetailsForm
          isOpen={isTransferredDetailsFormOpen}
          order={orderToSend}
          handleClose={this.handleTransferredDetailsFormClose}
          onSuccess={this.handleSuccessSent}
          currUser={currUser}
        />
        <EditTransferredDetailsForm
          isOpen={isEditTransferredDetailsFormOpen}
          order={orderToSend}
          handleClose={this.handleEditTransferredDetailsFormClose}
          onSuccess={this.handleSuccessEditTransferredDetail}
          currUser={currUser}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Orders);
