import React, { Component } from "react";
import { API } from "aws-amplify";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";

const styles = {
  root: {
    flexGrow: 1,
  },
};

class GamedayNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isResetting: false,
      reset: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isAuthenticating: false,
    });
  }

  sendReminder() {
    return API.get("v2", "communication/email/today_reminder");
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState({
      isResetting: true,
    });

    try {
      await this.sendReminder();

      this.setState({
        isResetting: false,
      });

      alert("Email(s) been sent");
    } catch ({ response }) {
      const { data } = response;

      this.setState({
        isResetting: false,
      });

      console.log(data.message);
      alert(data.message);
    }
  }

  renderForm() {
    const { isResetting } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={isResetting}
        >
          Send Today Notifications
        </Button>
      </form>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={4}>
            <Typography
              style={{ marginTop: "1.5rem", marginBottom: "3rem" }}
              variant="display2"
            >
              Gameday Email Notification
            </Typography>
            {this.renderForm()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GamedayNotification);
