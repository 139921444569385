import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Context from "../context";
import SpecialSectionSelect from "./SpecialSectionSelect";
import SpecialRowSelect from "./SpecialRowSelect";
import SectionSelect from "./SectionSelect";
import RowSelector from "./RowSelector";
import { getTypeOfTicketLabel } from "../libs/shared/helpers";

const styles = (theme) => ({
  FormControl: {
    marginTop: "1rem",
  },
});

class SeatDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTeam: "",
      isAisleSeat: false,
    };
  }

  renderSectionSelect(
    onSectionSelection,
    { selectedTeam, isSpecialSelected, section, selectedGames, games }
  ) {
    const gameId = selectedGames.length > 0 ? selectedGames[0] : null;
    const game = games.find(game => game.id === gameId);

    if (isSpecialSelected) {
      return (
        <SpecialSectionSelect
          selectedTeam={selectedTeam}
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else {
      return (
        <SectionSelect
          selectedTeam={selectedTeam}
          onSectionSelection={onSectionSelection}
          section={section}
          city={game.city}
        />
      )
    }
  }

  renderRowSelect(onRowSelection, { selectedTeam, section, rowNo, isSpecialSelected, selectedGames, games }) {
    const gameId = selectedGames.length > 0 ? selectedGames[0] : null;
    const game = games.find(game => game.id === gameId);
    if (isSpecialSelected) {
      return (
        <SpecialRowSelect
          selectedTeam={selectedTeam}
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else {
      return (
        <RowSelector
          selectedTeam={selectedTeam}
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
          city={game.city}
        />
      )
    }
  }

  renderSellOptions(onSellOptionSelection, { sellOption }) {
    const { classes } = this.props;

    return (
      <FormControl
        component="fieldset"
        className={classes.formControl}
        style={{ marginTop: "2rem" }}
      >
        <FormLabel component="legend">
          How do you want to sell your tickets?
        </FormLabel>
        <RadioGroup
          aria-label="Sell Option"
          name="sellOption"
          className={classes.group}
          value={sellOption}
          onChange={onSellOptionSelection}
        >
          <FormControlLabel
            value={2}
            className={classes.formControlLabel}
            control={<Radio />}
            label="Sell any, but don't leave a single ticket"
          />
          <FormControlLabel
            value={3}
            className={classes.formControlLabel}
            control={<Radio />}
            label="Sell all (keep my tickets together as a set)"
          />
          <FormControlLabel
            value={1}
            className={classes.formControlLabel}
            control={<Radio />}
            label="Sell any, even if it leaves a single remaining"
          />
          <FormControlLabel
            value={4}
            className={classes.formControlLabel}
            control={<Radio />}
            label="Sell in pairs"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Context.Consumer>
        {(context) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="headline">2. Enter Seat Details</Typography>

              <FormControl className={classes.FormControl}>
                <TextField
                  label="Seller Email"
                  name="sellerEmail"
                  value={context.state.sellerEmail}
                  onChange={context.handleSellerEmailChange}
                  margin="none"
                  type="text"
                />
              </FormControl>

              <FormControl className={classes.FormControl}>
                {this.renderSectionSelect(
                  context.onSectionSelection,
                  context.state
                )}
              </FormControl>

              <FormControl className={classes.FormControl}>
                {this.renderRowSelect(context.onRowSelection, context.state)}
              </FormControl>

              <FormControl className={classes.FormControl}>
                <TextField
                  label="No. of Seats"
                  name="noOfSeats"
                  value={context.state.noOfSeats}
                  onChange={context.handleNumberChange}
                  margin="none"
                  type="text"
                  inputProps={{
                    maxLength: 2,
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
              <FormControl className={classes.FormControl}>
                <TextField
                  name="seatNumberStart"
                  value={context.state.seatNumberStart}
                  type="number"
                  label="New Seat Number Start"
                  placeholder="New Seat Number Start"
                  min="0"
                  max="999"
                  onChange={context.handleSeatNumberChange}
                />
                <TextField
                  name="seatNumberEnd"
                  value={context.state.seatNumberEnd}
                  type="number"
                  label="New Seat Number End"
                  placeholder="New Seat Number End"
                  min="0"
                  max="999"
                  disabled={true}
                />
              </FormControl>
              <FormControlLabel
                className={classes.FormControl}
                label={
                  <Typography
                    style={{
                      display: "inline-block",
                      color: "rgb(33, 150, 243)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Aisle Seats
                  </Typography>
                }
                control={<Checkbox name="isAisleSeat" onChange={context.handleCheckboxChange} />}
              ></FormControlLabel>
              <FormControlLabel
                className={classes.FormControl}
                label={
                  <Typography
                    style={{
                      display: "inline-block",
                      color: "rgb(33, 150, 243)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Wheelchair
                  </Typography>
                }
                control={<Checkbox name="isWheelchair" onChange={context.handleCheckboxChange} />}
              ></FormControlLabel>
              <FormControlLabel
                className={classes.FormControl}
                label={
                  <Typography
                    style={{
                      display: "inline-block",
                      color: "rgb(33, 150, 243)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Obstructed View
                  </Typography>
                }
                control={<Checkbox name="isObstructedView" onChange={context.handleCheckboxChange} />}
              ></FormControlLabel>
              {this.renderSellOptions(
                context.onSellOptionSelection,
                context.state
              )}
              <FormControl component="fieldset" className={classes.formControl} style={{ marginTop: "2rem" }}>
                <FormLabel component="legend">Type of ticket</FormLabel>
                <RadioGroup aria-label="Type of ticket" name="typeOfTicket" className={classes.group} value={context.state.typeOfTicket} onChange={context.handleChange}>
                  <FormControlLabel value="Account Manager" className={classes.formControlLabel} control={<Radio />} label={getTypeOfTicketLabel("Account Manager", context.state.selectedTeam)} />
                  <FormControlLabel value="Ticketmaster" className={classes.formControlLabel} control={<Radio />} label={getTypeOfTicketLabel("Ticketmaster", context.state.selectedTeam)} />
                  {/* <FormControlLabel value="Link" className={classes.formControlLabel} control={<Radio />} label="Third-party Mobile Link (Secure, Amosa, etc.)" /> */}
                </RadioGroup>
              </FormControl>
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default withStyles(styles)(SeatDetailsForm);
