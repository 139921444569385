import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core';

import Context from '../context';
import { renderZoneName, renderRowName, getTypeOfTicketLabel } from '../libs/shared/helpers';

const styles = theme => ({
  FormControl: {
    marginTop: '1rem'
  }
});

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  integerLimit: 5,
  decimalLimit: 2,
  allowDecimal: true,
  allowLeadingZeroes: false
});

class SelectGamesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
      isSubmitting: false
    };
  }

  renderSeats(seats, handlePriceChange, saveSeats) {
    const sortedSeats = _.sortBy(
      seats,
      ['gameDate', 'orderNo', 'gameName'],
      'asc'
    );

    return (
      sortedSeats && (
        <Paper
          style={{
            marginTop: '2rem'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game</TableCell>
                <TableCell>Seat Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSeats.map(seat => {
                return (
                  <TableRow key={seat.game}>
                    <TableCell>{seat.gameName}</TableCell>
                    <TableCell>
                      <MaskedInput
                        mask={numberMask}
                        placeholder="Enter Price"
                        guide={true}
                        style={{
                          fontSize: '.875rem',
                          fontFamily: 'Roboto',
                          border: 'none',
                          borderBottom: '1px solid #888',
                          padding: 8
                        }}
                        onChange={e => handlePriceChange(e, seat.game)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            disabled={this.state.isSubmitting}
            onClick={() => {
              this.setState({ isSubmitting: true });
              saveSeats();
            }}
          >
            Save Seats
          </Button>
        </Paper>
      )
    );
  }

  renderSection(section, row, selectedTeam) {
    let zone;
    let zoneNo;

    if (section.includes("WC")) {
      zone = section;
      zoneNo = null;
    } else {
      zone = section.replace(/[0-9]/g, "").trim();
      zoneNo = Number(section.replace(/[^0-9]/g, ""));
    }

    return renderZoneName(zone, zoneNo, row.toString(), selectedTeam, false);
  }

  render() {
    return (
      <Context.Consumer>
        {context => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="headline">3. Add Seat Prices</Typography>

              <Typography variant="caption">Section</Typography>
              <Typography variant="body1">{this.renderSection(context.state.section, context.state.rowNo, context.state.selectedTeam)}</Typography>

              <Typography variant="caption">Row</Typography>
              <Typography variant="body1">{renderRowName(context.state.section, context.state.rowNo, context.state.selectedTeam, false)}</Typography>

              <Typography variant="caption">No. of Seats</Typography>
              <Typography variant="body1">{context.state.noOfSeats}</Typography>
              
              <Typography variant="caption">Seat Numbers</Typography>
              <Typography variant="body1">{context.state.seatNumberStart} - {context.state.seatNumberEnd}</Typography>

              <Typography variant="caption">Type of Ticket</Typography>
              <Typography variant="body1">{context.state.typeOfTicket ? getTypeOfTicketLabel(context.state.typeOfTicket, context.state.selectedTeam) : "-"}</Typography>

              <Typography variant="subheading">Games</Typography>

              {this.renderSeats(
                context.state.seats,
                context.handlePriceChange,
                context.saveSeats
              )}
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default withStyles(styles)(SelectGamesForm);
