import React, { Component } from "react";
import { API } from "aws-amplify";
import querySearch from "stringquery";
import moment from "moment-timezone";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { renderZoneName, renderRowName, getTypeOfTicketLabel } from "../libs/shared/helpers";

const styles = {
  root: {
    flexGrow: 1,
  },
  FormControl: {
    marginTop: "1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  changeLogs: {
    marginTop: "2rem",
  },
  changeLog: {
    marginBottom: "2rem",
  },
  orders: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
};

class SeatDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seat: null,
    };
  }

  async componentWillMount() {
    const seat = await this.seat();
    this.setState({
      seat,
    });
  }

  seat() {
    const { id } = this.props.match.params;
    const { game } = querySearch(this.props.location.search);

    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${id}&include_seller_details=true`
    );
  }

  render() {
    const { classes } = this.props;
    const { seat } = this.state;

    return (
      seat && (
        <div className={classes.root} id="SeatDetails">
          <Grid container spacing={32}>
            <Grid item xs={12} md={6}>
              <Typography variant="display2">Seat Details</Typography>
              <div
                style={{
                  marginTop: "1rem",
                }}
              >
                <Typography variant="caption">Game</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.gameDetails.isTbd
                    ? seat.gameName.split(" - ")[0] + " - TBD"
                    : seat.gameName}
                </Typography>
                <Typography variant="caption">Section</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                </Typography>
                <Typography variant="caption">Row</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)} {seat.isAisleSeat ? "Aisle Seat" : ""}
                </Typography>
                <Typography variant="caption">Price</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  CAD$ {seat.price.toFixed(2)} ea
                </Typography>
                <Typography variant="caption">Available Seats</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.noOfSeats - seat.seatsSold} / {seat.noOfSeats}
                </Typography>
                <Typography variant="caption">Seller Name</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.sellerFullName ? seat.sellerFullName : "-"}
                </Typography>
                <Typography variant="caption">Type of Ticket</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.typeOfTicket ? getTypeOfTicketLabel(seat.typeOfTicket, seat.gameDetails.homeTeamSlug) : "-"}
                </Typography>
                <Typography variant="caption">Seller Email</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.sellerEmail ? seat.sellerEmail : "-"}
                </Typography>
                <Typography variant="caption">Seller Mobile No</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.sellerMobileNo ? seat.sellerMobileNo : "-"}
                </Typography>
                <Typography variant="caption">Created</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.created
                    ? moment
                        .tz(seat.created, "America/Edmonton")
                        .format("MMM DD, YYYY hh:mm A")
                    : "-"}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.isAisleSeat ? "*Aisle Seats" : null}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.isWheelchair ? "*Wheelchair" : null}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.isObstructedView ? "*Obstructed View" : null}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="display1">Changelogs</Typography>
              <div className={classes.changeLogs}>
                {seat.changeLogs &&
                  _.chain(seat.changeLogs)
                    .orderBy("date", "desc")
                    .map(({ date, changes, user }) => {
                      return (
                        <div key={date} className={classes.changeLog}>
                          <Typography
                            variant="caption"
                            style={{ marginBottom: ".5rem" }}
                          >
                            {moment
                              .tz(date, "America/Edmonton")
                              .format("MMM DD, YYYY hh:mm A")}{" "}
                            &mdash; {user && user}
                          </Typography>
                          {Object.keys(changes).map((key) => {
                            return (
                              <Typography
                                variant="body1"
                                style={{ marginBottom: ".5rem" }}
                              >
                                {key}:{" "}
                                {typeof changes[key] === "boolean"
                                  ? changes[key].toString()
                                  : changes[key]}
                              </Typography>
                            );
                          })}
                        </div>
                      );
                    })
                    .value()}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="display1">Buyer Information</Typography>
              <div className={classes.orders}>
                {seat.orders &&
                  _.chain(seat.orders)
                    .orderBy("createdAt", "asc")
                    .map(({ createdAt, email, name }) => {
                      return (
                        <div key={createdAt} className={classes.orders}>
                          <Typography
                            variant="caption"
                            style={{ marginBottom: ".5rem" }}
                          >
                            {moment
                              .tz(createdAt, "America/Edmonton")
                              .format("MMM DD, YYYY hh:mm A")}{" "}
                            &mdash; {name && name} {" "} &mdash;  {email && email} 
                          </Typography>
                        </div>
                      );
                    })
                    .value()}
              </div>
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}

export default withStyles(styles)(SeatDetails);
