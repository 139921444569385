import React, { Component } from "react";
import { API } from "aws-amplify";
import _ from "lodash";

import Context from "../context";
import { testDataCheck } from "../libs/shared/helpers";

class SeatsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: "",
      rowNo: 0,
      noOfSeats: 2,
      seatNumberStart: "",
      seatNumberEnd: "",
      selectedGames: [],
      sellerEmail: "",
      games: null,
      seats: [],
      isAllGamesSelected: false,
      selectedTeam: "",
      isLoadingGames: true,
      isSpecialSelected: false,
      sellOption: 2,
      isAisleSeat: false,
      isWheelchair: false,
      isObstructedView: false,
      typeOfTicket: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleSeatNumberChange = this.handleSeatNumberChange.bind(this);
    this.onSectionSelection = this.onSectionSelection.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.saveSeats = this.saveSeats.bind(this);
    this.selectAllGames = this.selectAllGames.bind(this);
    this.handleSellerEmailChange = this.handleSellerEmailChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onTeamSelection = this.onTeamSelection.bind(this);
    this.onSellOptionSelection = this.onSellOptionSelection.bind(this);
  }

  loadGames(selectedTeam) {
    return API.get("v2", `marketplace/events/by/homeTeamSlug/${selectedTeam}`);
  }

  updateSeats(section, rowNo, noOfSeats) {
    const { seats } = this.state;

    const updatedSeats = seats.map((seat) => {
      const zone = section.replace(/[0-9]/g, "").trim();
      const zoneNo = Number(section.replace(/^\D+/g, ""));

      return {
        ...seat,
        ...{
          zone,
          zoneNo: zoneNo === 0 ? null : zoneNo,
          row: rowNo,
          noOfSeats,
        },
      };
    });

    this.setState({
      seats: updatedSeats,
    });
  }

  onTeamSelection = async (slug) => {
    this.setState({
      isLoadingGames: true,
    });

    const games = await this.loadGames(slug);
    const tbdGames = _.chain(games)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return game.isTbd && !doesNeedTestData && !game.isArchived;
      })
      .sortBy("playoffSequence")
      .value();
    const regularGames = _.chain(games)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return !game.isTbd && !doesNeedTestData && !game.isArchived;
      })
      .sortBy("date")
      .value();
    this.setState({
      selectedTeam: slug,
      games: [...regularGames, ...tbdGames],
      isLoadingGames: false,
      selectedGames: [],
      seats: []
    });
  };

  onSectionSelection = (section) => {
    this.setState({
      section,
    });

    if (this.state.selectedGames.length > 0) {
      const { rowNo, noOfSeats } = this.state;
      this.updateSeats(section, rowNo, noOfSeats);
    }
  };

  onRowSelection = (row) => {
    this.setState({
      rowNo: row,
    });

    if (this.state.selectedGames.length > 0) {
      const { section, noOfSeats } = this.state;
      this.updateSeats(section, row, noOfSeats);
    }
  };

  handleSellerEmailChange(e) {
    this.setState({
      sellerEmail: e.target.value,
    });
  }
  handleCheckboxChange(e) {
    if (e.target === undefined) {
      return null;
    }
    this.setState(
      {
        [e.target.name]: e.target.checked,
      },
      () => {
        console.log({ state: this.state, props: this.props });
      }
    );
  }

  handleNumberChange(e) {
    const prevVal = this.state[e.target.name];

    const value = e.target.validity.valid ? e.target.value : prevVal;

    this.setState({
      [e.target.name]: Number(value),
    });

    if (this.state.selectedGames.length > 0) {
      const { section, rowNo, noOfSeats } = this.state;
      const row = e.target.name === "rowNo" ? Number(e.target.value) : rowNo;
      const seats =
        e.target.name === "noOfSeats" ? Number(e.target.value) : noOfSeats;

      this.updateSeats(section, row, seats);
    }
  }

  handleSeatNumberChange(e) {
    const { noOfSeats } = this.state;
    const prevVal = Number(this.state[e.target.name]);
    const newVal = Number(e.target.value);
    const value = e.target.validity.valid ? newVal : prevVal;

    this.setState({
      [e.target.name]: value > 0 ? value : "",
      seatNumberEnd: newVal > 0 ? newVal + Number(noOfSeats) - 1 : "",
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  seatDetails(gameId) {
    const { games } = this.state;

    const game = _.find(games, (game) => game.id === gameId);

    return {
      game: gameId,
      gameName: game.longName,
      gameDate: game.date,
      gameTimezone: game.timezone,
      isPlayoffs: game.isPlayoffs,
      round: game.round,
      gameNo: game.game,
      price: 0,
      orderNo: game.orderNo,
    };
  }

  async saveSeats() {
    const {
      seats,
      section,
      rowNo,
      noOfSeats,
      sellerEmail,
      sellOption,
      isAisleSeat,
      isObstructedView,
      seatNumberEnd,
      seatNumberStart,
      isWheelchair,
      typeOfTicket
    } = this.state;

    let zone;
    let zoneNo;

    if (section.includes("WC")) {
      zone = section;
      zoneNo = null;
    } else {
      zone = section.replace(/[0-9]/g, "").trim();
      zoneNo = Number(section.replace(/[^0-9]/g, ""));
    }

    const mappedSeats = seats.map((seat) => {
      return {
        ...seat,
        ...{
          noOfSeats,
          row: rowNo,
          zone,
          zoneNo: zoneNo === 0 ? null : zoneNo,
          sellerEmail: sellerEmail !== "" ? sellerEmail.trim() : "-",
          sellOption,
          isAisleSeat,
          isObstructedView,
          seatNumberEnd: Number(seatNumberEnd),
          seatNumberStart: Number(seatNumberStart),
          isWheelchair,
          typeOfTicket
        },
      };
    });

    try {
      await API.post("v2", "marketplace/listings/create-direct", {
        body: {
          seats: mappedSeats,
          user: this.props.currUserEmail,
        },
      });

      window.location = `/seats?team=${this.state.selectedTeam}&game=${mappedSeats[0].game}`;
    } catch (e) {
      alert(e);
    }
  }

  selectAllGames = () => {
    const { games, isAllGamesSelected } = this.state;
    const newSeats = [];

    if (!isAllGamesSelected) {
      const gameIds = _.map(games, (game) => {
        if (game.isSpecial) {
          return;
        }

        newSeats.push(this.seatDetails(game.id));
        return game.id;
      });

      this.setState({
        selectedGames: gameIds,
        isAllGamesSelected: !isAllGamesSelected,
        seats: newSeats,
      });
    } else {
      this.setState({
        selectedGames: [],
        isAllGamesSelected: !isAllGamesSelected,
        seats: [],
      });
    }
  };

  handleToggle = (game) => () => {
    const { selectedGames, seats } = this.state;
    const currentIndex = selectedGames.indexOf(game.id);
    let newSelected = [...selectedGames];
    const newSeats = [...seats];

    if (currentIndex === -1) {
      if (game.isSpecial) {
        newSelected = [];

        this.setState({
          isSpecialSelected: true,
        });
      } else {
        this.setState({
          isNormalSelected: true,
        });
      }

      newSelected.push(game.id);
      newSeats.push(this.seatDetails(game.id));
    } else {
      if (game.isSpecial) {
        newSelected = [];

        this.setState({
          isSpecialSelected: false,
        });
      } else if (selectedGames.length === 1) {
        this.setState({
          isNormalSelected: false,
        });
      }
      newSelected.splice(currentIndex, 1);
      newSeats.splice(currentIndex, 1);
    }

    this.setState({
      selectedGames: newSelected,
      seats: newSeats,
    });
  };

  handlePriceChange(e, gameId) {
    const { seats } = this.state;

    let price = e.target.value.replace(/[^\d.]+/g, "");

    const updatedSeats = _.map(seats, (seat) => {
      if (seat.game === gameId) {
        seat.price = Number(price);
      }

      return seat;
    });

    this.setState({
      seats: updatedSeats,
    });
  }

  onSellOptionSelection({ target }) {
    this.setState({
      sellOption: parseInt(target.value),
    });
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          games: this.state.games,
          handleChange: this.handleChange,
          handleNumberChange: this.handleNumberChange,
          handleSeatNumberChange: this.handleSeatNumberChange,
          onSectionSelection: this.onSectionSelection,
          handleToggle: this.handleToggle,
          handlePriceChange: this.handlePriceChange,
          saveSeats: this.saveSeats,
          selectAllGames: this.selectAllGames,
          handleSellerEmailChange: this.handleSellerEmailChange,
          handleCheckboxChange: this.handleCheckboxChange,
          onTeamSelection: this.onTeamSelection,
          onRowSelection: this.onRowSelection,
          isSpecialSelected: this.state.isSpecialSelected,
          isLoadingGames: this.state.isLoadingGames,
          onSellOptionSelection: this.onSellOptionSelection,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default SeatsProvider;
